import {
  Box,
  Flex,
  Button,
  Container,
  Stack,
  Text,
  GridItem,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import ImgTextModule from '../components/elements/ImgTextModule';
import RouterLink from '../components/Utility/Routing/RouterLink';
import KontaktFooter from '../components/KontaktFooter';
import Managementslider from '../components/elements/ManagementSlider';
import GImage from '../components/Utility/GImage';
const Leistungen = () => {
  const { setColorMode } = useColorMode();
  const [isModalActive, setIsModalActive] = useState(true);

  const variant = {
    isModalActive: {
      scale: 0.9,
      opacity: 0.5,
      transition: { duration: 1 },
    },
    isModalInactive: {
      scale: 1,
      opacity: 1,
      transition: { type: 'spring', stiffness: 300, damping: 24 },
    },
  };

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Personalmanagement und Managementberatung</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https://kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      {/* CONTAINER MAGICTEXT */}
      <Box bg='black'>
        <Box position={'relative'}>
          <Box position={'sticky'} top={0} minH={'50vh'}>
            <AnimatePresence mode='wait' initial={true}>
              <motion.div
                key='modal'
                layoutId={'leistungen-transit-ID'}
                layout
                initial={{
                  scale: 1,
                  opacity: 1,

                  transition: { type: 'spring', stiffness: 300, damping: 24 },
                }}
                animate={{
                  scale: 0.9,
                  opacity: 0.5,

                  transition: { type: 'spring', stiffness: 300, damping: 24 },
                }}
                exit={{
                  scale: 1,
                  opacity: 1,

                  transition: { type: 'spring', stiffness: 300, damping: 24 },
                }}
              >
                <Box
                  as='section'
                  w={'100%'}
                  h={'100vh'}
                  display={'grid'}
                  background={'brand.gray.100'}
                >
                  <GridItem
                    gridRow='1'
                    gridColumn='1'
                    zIndex='1'
                    position={'relative'}
                    overflow={'hidden'}
                  >
                    <GImage
                      src={
                        'mood/rodeo-project-management-software-ONe-snuCaqQ-unsplash.jpg'
                      }
                      alt={' '}
                      w={'100%'}
                      h={'100%'}
                      objectFit={'cover'}
                      objectPosition={'center center'}
                      position={'absolute'}
                      top={0}
                      left={0}
                      opacity={0.2}
                    />
                  </GridItem>

                  <GridItem
                    gridRow='1'
                    gridColumn='1'
                    zIndex='2'
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                  >
                    <Box>
                      <Container variant='s' mt={8}>
                        <Box
                          p={{ base: 0, lg: '8' }}
                          display='flex'
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <Text
                            mx={'auto'}
                            textAlign={'center'}
                            color='white'
                            fontWeight='normal'
                            fontSize={{ base: '2xl', lg: '3xl' }}
                          >
                            Unsere Leistungen
                          </Text>
                          <Text
                            mx={'auto'}
                            textAlign={'center'}
                            color='brand.green'
                            fontWeight='normal'
                            fontSize={{ base: '3xl', lg: '6xl' }}
                          >
                            Für Menschen und Unternehmen.
                          </Text>

                          <Text
                            color='brand.gray.1000'
                            mb={24}
                            maxW={{ base: '100%', lg: '50%' }}
                            fontSize='xl'
                            lineHeight={'short'}
                            mx={'auto'}
                            textAlign={'center'}
                          >
                            kdw HR bietet Kunden Dienstleistungen von der
                            Personalvermittlung, über qualifizierte
                            Arbeitnehmerüberlassung bis hin zu Management- und
                            Fördermittelberatung im gesamten
                            Personalmanagementbereich.
                          </Text>

                          <RouterLink
                            mx={'auto'}
                            display={'inline-block'}
                            isExternal={'false'}
                            href={'/foerdermittelberatung'}
                          >
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={9}
                              fontSize='md'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.green'
                            >
                              Fördermittelmanagement
                            </Button>
                          </RouterLink>
                          <RouterLink
                            mx={'auto'}
                            display={'inline-block'}
                            isExternal={'false'}
                            href={'/managementprojektberatung'}
                          >
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={4}
                              fontSize='md'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.violet'
                              whiteSpace='normal'
                              height='auto'
                              blockSize='auto'
                            >
                              Management- und Projektberatung
                            </Button>
                          </RouterLink>
                          <RouterLink
                            mx={'auto'}
                            display={'inline-block'}
                            isExternal={'false'}
                            href={'/arbeitnehmerueberlassungvermittlung'}
                          >
                            <Button
                              mt={2}
                              px={14}
                              pt={4}
                              pb={4}
                              fontSize='md'
                              textAlign='center'
                              variant={'solid'}
                              color='white'
                              bg='brand.blue'
                              whiteSpace='normal'
                              height='auto'
                              blockSize='auto'
                            >
                              Arbeitnehmerüberlassung und -vermittlung
                            </Button>
                          </RouterLink>
                        </Box>
                      </Container>
                    </Box>
                  </GridItem>
                </Box>
              </motion.div>
            </AnimatePresence>
          </Box>
          <RouterLink
            mx={'auto'}
            mt={'12'}
            ml={6}
            top={6}
            left={6}
            zIndex={'10000000'}
            position={'fixed'}
            display={'inline-block'}
            isExternal={'false'}
            link={'/leistungen/'}
          >
            <Button
              w={14}
              h={14}
              mt={0}
              display={'flex'}
              fontSize='xl'
              textAlign='center'
              variant={'solid'}
              color='white'
              bg='brand.red'
              _hover={{
                bg: 'brand.green',
              }}
            >
              <svg
                fill='white'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
              >
                <g id='close'>
                  <path
                    id='x'
                    d='M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z'
                  />
                </g>
              </svg>
            </Button>
          </RouterLink>
          <Box position={'sticky'} mx={'auto'} mt={'-60vh'}>
            <FadeInAnimation
              delay={0.5}
              duration={0.5}
              threshold={0}
              initialY={12}
            >
              <Container variant={''} px={0} overflow={'hidden'}>
                <Managementslider />
              </Container>
            </FadeInAnimation>
          </Box>
        </Box>
        <KontaktFooter />
      </Box>
    </>
  );
};

export default Leistungen;
